@import "../colours.css";
@import "../typography.css";

.modal {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(0, 0, 0, 0.3);
  z-index: 110;
}

.modal__content {
  max-height: 98%;
  background: var(--base-white);
  border-radius: 0.5rem;
  overflow-y: auto;
}

.modal__body {
  padding: 2rem;
}

.modal__buttons {
  margin-top: 1rem;
  display: flex;
  justify-content: space-around;
}

.modal__buttons > .btn:last-child {
  margin-left: 0.5rem;
}

.modal__header {
  display: flex;
  align-items: center;
  background: var(--brand-secondary);
  color: var(--base-white);
  padding: 1rem;
  border-radius: 0.5rem 0.5rem 0 0;
}

.modal__title {
  font: var(--content-type);
  font-weight: bold;
  padding-right: 1rem;
}

.modal__close {
  margin-left: auto;
  cursor: pointer;
}
